import { getSegmentAnonymousId } from 'helpers/analytics/segment-helper';
import { ConsoleType, consoleLoggerProps } from 'helpers/types/analytics';
import { store } from 'redux/store';
/**
* Logs a message to the console with specific details.
*
* @param {consoleLoggerProps} props - The properties to configure the log.
* @param {string} props.category - The log category (e.g., "API_FAULURE" or "FAULT_TOLERANCE").
* @param {ConsoleType} props.consoleType - The console method to use (ERROR, WARN, LOG, INFO, DEBUG).
* @param {string} props.decision - The decision or message to log.
* @param {Object} [props.details] - Additional log details.
*
* @example
* await consoleLogger({
*   category: 'API_FAILURE',
*   consoleType: ConsoleType.LOG,
*   decision: 'Hiding ABC section',
*   details: {
         errorCode: "SERVICE_UNAVAILABLE",
         service: "SEGMENT",
         retryAttempts: 4,
         endpoint: "https://api.xyz.com/endpoint",
         description: "The service returned a 503 response. Please check service status or network connectivity.",
       }
* });
*/
export const consoleLogger = async ({ category, consoleType, decision, details }: consoleLoggerProps) => {
  const segmentAnonymoudId = await getSegmentAnonymousId();
  const id = store.getState().accountReducer?.user?.attributes?.sub;
  const consoleObject = JSON.stringify({
    category,
    decision,
    userId: id || null,
    segmentAnonymoudId,
    details,
  });
  switch (consoleType) {
    case ConsoleType.ERROR:
      console.error(consoleObject);
      break;
    case ConsoleType.WARN:
      console.warn(consoleObject);
      break;
    case ConsoleType.LOG:
      console.log(consoleObject);
      break;
    case ConsoleType.DEBUG:
      console.debug(consoleObject);
      break;
    case ConsoleType.INFO:
      console.info(consoleObject);
      break;
    case ConsoleType.FATAL:
      console.error(consoleObject);
  }
};
