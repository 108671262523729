export enum ConsoleLoggerCategories {
  API_FAILURE = 'API_FAILURE',
  FAULT_TOLERANCE = 'FAULT_TOLERANCE',
  API_CALL = 'API_CALL',
}

export enum ConsoleType {
  ERROR = 'ERROR',
  WARN = 'WARN',
  LOG = 'LOG',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  FATAL = 'FATAL',
}
export interface consoleLoggerProps {
  category: ConsoleLoggerCategories;
  consoleType: ConsoleType;
  decision?: string;
  details: Record<string, any>;
}

export enum ConsoleLoggerServices {
  PRODUCT = 'PRODUCT',
  ANALYTICS = 'ANALYTICS',
  ACCOUNT = 'ACCOUNT',
  LOYALTY = 'LOYALTY',
  AUTHENTICATION = 'AUTHENTICATION',
  UPLOAD = 'UPLOAD',
  CUSTOMERSERVICE = 'CUSTOMER SERVICE',
  ORDER = 'ORDER',
  CHECKOUT = 'CHECKOUT',
  SEARCH = 'SEARCH',
  CART = 'CART',
  CMS = 'CMS',
  JANE = 'JANE',
  JANEALGOLIA = 'JANE ALGOLIA',
  GTIALGOLIA = 'GTI ALGOLIA',
  SEGMENT = 'SEGMENT',
  ACCOUNTSERVICE = 'ACCOUNT SERVICE',
  COGNITO = 'COGNITO',
  AWSCONNECT = 'AWS CONNECT',
  LOYALTYSERVICE = 'LOYALTY SERVICE',
  CONTENTFUL = 'CONTENTFUL',
}
